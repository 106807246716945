<script setup lang="ts">
</script>

<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <q-page class="flex flex-center bg-grey-100 dark:bg-gray-800">
        <slot />
      </q-page>
    </q-page-container>
  </q-layout>
</template>
